import { mapActions, mapGetters } from 'vuex'
import ZbLoginMixin from './ZbLoginMixin'
export default {
  name: 'ZbLoginComponent',
  mixins: [ ZbLoginMixin ],
  data () {
    return {
      showPass: false,
      loading: false,
      selectedLang: localStorage.getItem('currentLang') || 'es',
      availableLangs: []
    }
  },
  computed: {
    ...mapGetters({
      currentLang$: 'langModule/currentLang',
      authCredentials$: 'authModule/authCredentials'
    }),
    logoZoundHome () {
      return `${process.env.BASE_URL}images/zoundhome.png`
    }
  },
  created () {
    this.translateDropdown(this.currentLang$)
  },
  watch: {
    authCredentials$: {
      handler: function (newVal) {
        if (newVal && newVal.token) {
          this.loading = false
          this.$router.push({ name: 'admin' })
        }
      },
      immediate: true
    },
    selectedLang: {
      handler: function (newVal) {
        this.translateDropdown(newVal)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('langModule', ['setLang']),
    ...mapActions('authModule', ['login']),
    selectLang () {
      const currentLang = this.selectedLang
      this.setLang({ currentLang })
      this.$i18n.locale = this.currentLang$
      this.$vuetify.lang.current = this.currentLang$
    },
    translateDropdown (locale) {
      this.availableLangs = [
        {
          value: this.$i18n.t('general.en', locale),
          id: 'en'
        },
        {
          value: this.$i18n.t('general.es', locale),
          id: 'es'
        }
      ]
    },
    async loginSubmt () {
      this.loading = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const auxResponse = await this.login({ url: 'auth/login', email: this.loginData.email, password: this.loginData.password })
        if (!auxResponse) {
          this.serverError = auxResponse
          this.loading = false
        }
      } else {
        this.loading = false
      }
    }
  }
}
