import { required, email } from 'vuelidate/lib/validators'
import { ZbLoginModel } from './ZbLoginModel'

export default {
  data: () => ({
    loginData: new ZbLoginModel({}),
    serverError: false
  }),
  validations: {
    loginData: {
      email: { required, email },
      password: { required }
    }
  },
  computed: {
    emailErrors () {
      const errors = []
      if (!this.$v.loginData.email.$dirty) return errors
      !this.$v.loginData.email.required && errors.push(this.$t('validator.required', { A: this.$t('login.email') }))
      !this.$v.loginData.email.email && errors.push(this.$t('validator.email'))
      if (this.serverError) {
        errors.push(this.$t('validator.emailServer'))
      }
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.loginData.password.$dirty) return errors
      !this.$v.loginData.password.required && errors.push(this.$t('validator.required', { A: this.$t('login.password') }))
      if (this.serverError) {
        errors.push(this.$t('validator.passwordServer'))
      }
      return errors
    }
  },
  methods: {
    initData (param) {
      this.loginData = new ZbLoginModel(param)
    }
  }
}
